<template>
  <div class="main-container grid grid-cols-12 row-gap-10 md:col-gap-10">
    <template v-if="loading">
      <div class="h-40 col-span-12 flex flex-col justify-center items-center">
        <p>Confirming that the booking has been scheduled.</p>
        <loading-icon class="w-10 h-10 text-pink-500" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ConfirmScheduled",
  data() {
    return {
      loading: true
    };
  },
  created() {
    this.confirmScheduled(this.$route.params.bookingId);
  },

  methods: {
    async confirmScheduled(id) {
      try {
        await this.$store.dispatch("dashboard/bookings/confirmScheduled", id);
        this.$router.push({ name: "Activity", params: { bookingId: id } });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
